export const getValidedBoule = (boule, user = null) => {
  let valid = true;
  let option = 1;
  let symb = ".";
  let lotto;
  let formula = null;

  if (boule.toLowerCase() === "bp") {
    formula = "BP";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "rv") {
    formula = "rv";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "l3") {
    formula = "l3";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "ma") {
    formula = "ma";
    return { valid, lotto, option, symb, formula };
  }  else if (boule.toLowerCase() === "man") {
    formula = "man";
    return { valid, lotto, option, symb, formula };
  }else if (boule.toLowerCase() === "l4") {
    formula = "l4";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "p0") {
    formula = "p0";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "p1") {
    formula = "p1";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "p2") {
    formula = "p2";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "p3") {
    formula = "p3";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "p4") {
    formula = "p4";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "p5") {
    formula = "p5";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "p6") {
    formula = "p6";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "p7") {
    formula = "p7";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "p8") {
    formula = "p8";
    return { valid, lotto, option, symb, formula };
  } else if (boule.toLowerCase() === "p9") {
    formula = "p9";
    return { valid, lotto, option, symb, formula };
  }

  if (boule?.length === 2) {
    lotto = "BO";
  } else if (boule?.length === 3 && boule[0].toLowerCase() === "p") {
    lotto = "P2";
  } else if (boule?.length === 6 && boule[0].toLowerCase() === "p") {
    lotto = "P5";
  } else if (boule?.length === 3) {
    lotto = "L3";
  } else if (boule?.length === 4 && boule[0].toLowerCase() === "b") {
    lotto = "L3B";
    if (new Set(boule?.slice(1)).size < boule?.slice(1)?.length) {
      valid = false;
    }
  } else if (boule?.length === 4) {
    lotto = "MA";
    
  } else if (boule?.length === 6) {
    option = boule?.slice(-1);
    symb = boule?.slice(-2, -1);
    lotto = "L4";
    if (!["1", "2", "3", "0"].includes(option)) {
      valid = false;
    }
  } else if (boule?.length === 7) {
    option = boule?.slice(-1);
    lotto = "L5";
    if (!["1", "2", "3", "0"].includes(option)) {
      valid = false;
    }
  } else {
    lotto = null;
    valid = false;
  }

  return { valid, lotto, option, symb, formula };
};

export function formatAmount(montant) {
  let mnt = parseFloat(montant)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return mnt;
}

export function reverse_number(num) {
  console.log('li antre nan reverse lan ',num)
  const numStr = num.toString();
  if (numStr.length <= 2) {
    return numStr.split("").reverse().join("");
  } else if (numStr.length === 3) {
    return numStr[0] + numStr.slice(1, 3).split("").reverse().join("");
  } else if (numStr.length === 4) {
    console.log('li antre nan reverse lan 44 lan',num)
    return numStr[1] + numStr[0] + numStr[3] + numStr[2];
  } else {
    return numStr[0] + numStr.slice(3, 5) + numStr.slice(1, 3);
  }
}

export const transformedBoule = (boule, lotto) => {
  return lotto === "L4" || lotto === "L5"
    ? boule.slice(0, -2)
    : lotto === "P2" || lotto === "P5" || lotto === "L3B"
    ? boule.slice(1)
    : boule;
};

export const restoreTransformedBoule = (
  boule,
  lotto,
  option = "",
  prefixe = ""
) => {
  return lotto === "L4" || lotto === "L5"
    ? boule + "." + option
    : lotto === "P2" || lotto === "P5" || lotto === "L3B"
    ? prefixe + boule
    : boule;
};

export const isValidNumber = (value) => {
  console.log('lap teste value an laa',value)
  return /^\d+$/.test(value);
};

export const isBouleExist = (boule, bets, selectedTirage) => {
  const existingData = bets?.find(
    (data) => data.boule === boule && data.tirage === selectedTirage
  );

  return existingData ? existingData.id : -1;
};

export const isBoulePaire = (boule) => {
  const length = boule.toString().length;

  if (length === 2) {
    return boule[0] === boule[1];
  } else if (length === 3) {
    return boule[1] === boule[2];
  } else if (length === 4) {
    return (
      boule[0] === boule[1] && boule[1] === boule[2] && boule[2] === boule[3]
    );
  } else if (length === 5) {
    return (
      boule[1] === boule[2] && boule[2] === boule[3] && boule[3] === boule[4]
    );
  }

  return false;
};

export function getLottoName(lotto) {
  switch (lotto) {
    case "MA":
      return "Mariage";
    case "BO":
      return "Borlette";
    case "P2":
      return "Pick2";
    case "P5":
      return "Pick5";
    case "L3B":
      return "Lotto3Box";
    case "L3":
      return "Lotto3";
    case "L4":
      return "Lotto4";
    case "L5":
      return "Lotto5";
    default:
      return "Unknown";
  }
}

export const generateBoulePaire = () => {
  const boulesPaires = [];
  for (let i = 0; i <= 9; i++) {
    const boulePaire = `${i}${i}`;
    if (!boulesPaires.includes(String(boulePaire))) {
      boulesPaires.push(String(boulePaire));
    }
  }
  return boulesPaires;
};

export const generateBoulePoint = (point) => {
  const boulesPoint = [];

  for (let i = 0; i <= 9; i++) {
    const nouvelleBoule = `${i}${point}`;
    if (!boulesPoint.includes(nouvelleBoule)) {
      boulesPoint.push(nouvelleBoule);
    }
  }

  return boulesPoint;
};

export const generateBouleReverce = (bets) => {
  const boulesReverces = [];
  for (const betsItem of bets) {
    const { boule, lotto, option } = betsItem;
    const bouleReverce = restoreTransformedBoule(
      reverse_number(transformedBoule(boule, lotto)),
      lotto,
      ["L4", "L5"].includes(lotto) ? option : "",
      lotto === "P2" ? "p" : lotto === "P5" ? "p" : lotto === "L3B" ? "b" : ""
    );
    if (!isBoulePaire(transformedBoule(boule, lotto))) {
      if (!boulesReverces.includes(String(bouleReverce))) {
        boulesReverces.push(String(bouleReverce));
      }
    }
  }
  return boulesReverces;
};

export const generateLotto3Auto = () => {
  const boulesL3 = [];
  for (let i = 0; i <= 9; i++) {
    const bouleL3 = `${i}${i}${i}`;
    boulesL3.push(bouleL3);
  }
  return boulesL3;
};


export const generateMariageAuto = (bets) => {
  console.log('generateMariageAuto000000000000000000')
  const boulesMariages = [];

  for (const boule1 of bets) {
    for (const boule2 of bets) {
      if (
        !boulesMariages.includes(`${boule1.boule}${boule2.boule}`) &&
        !boulesMariages.includes(`${boule2.boule}${boule1.boule}`)
      ) {
        const nouvelleBouleMariage = `${boule1.boule}${boule2.boule}`;
        if (
          boule1.lotto === "BO" &&
          boule2.lotto === "BO" &&
          !boulesMariages.includes(nouvelleBouleMariage)
        ) {
          boulesMariages.push(nouvelleBouleMariage);
        }
      }
    }
  }

  return boulesMariages;
};


export const generateMariageAutoNormal = (bets) => {
  console.log('generateMariageAutoNormallllllllll')
  const boulesMariages = new Set();

  for (const boule1 of bets) {
    for (const boule2 of bets) {
      if (boule1.boule !== boule2.boule && boule1.lotto === "BO" && boule2.lotto === "BO") {
        const mariage = [boule1.boule, boule2.boule].sort().join(""); // Trie pour éviter les inversions
        boulesMariages.add(mariage);
      }
    }
  }

  return Array.from(boulesMariages);
};









export const generateL4Auto = (bets, option) => {
  const boulesL4 = [];

  for (const boule1 of bets) {
    for (const boule2 of bets) {
      const nouvelleBouleL4 = `${boule1.boule}${boule2.boule}.${option}`;
      if (
        boule1.lotto === "BO" &&
        boule2.lotto === "BO" &&
        !boulesL4.includes(nouvelleBouleL4)
      ) {
        boulesL4.push(nouvelleBouleL4);
      }
    }
  }

  return boulesL4;
};

export const generatePermutationsL4 = (input) => {
  const inputStr = input.toString();
  const boule1 = inputStr.slice(0, 2);
  const boule2 = inputStr.slice(2);

  const boules = [
    inputStr,
    boule2 + boule1,
    boule1.split("").reverse().join("") + boule2,
    boule2 + boule1.split("").reverse().join(""),
    boule1 + boule2.split("").reverse().join(""),
    boule2.split("").reverse().join("") + boule1,
    boule1.split("").reverse().join("") + boule2.split("").reverse().join(""),
    boule2.split("").reverse().join("") + boule1.split("").reverse().join(""),
  ];

  return boules;
};

export const convertToDict = (bets) => {
  const result = { ficheData: [] };
  const tempDict = {};

  bets.forEach((item) => {
    const { tirage, boule, lotto, option, montant } = item;
    if (!tempDict[tirage]) {
      tempDict[tirage] = { tirage, bouleLists: [] };
    }

    const bouleFix = transformedBoule(boule, lotto);
    tempDict[tirage].bouleLists.push({
      boule: String(bouleFix),
      lotto,
      option: String(option),
      montant: parseFloat(montant),
    });
  });

  result.ficheData = Object.values(tempDict);
  return result;
};
