import React, { useState } from "react";
import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, Button } from "@mui/material";
// hooks
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { HEADER } from "../../../config";
// components
//
import AccountPopover from "./AccountPopover";
import TutoPopover from "./TutoPopover";
import DateTimeDisplay from "../../../components/DateTimeDisplay";
import Tickets from "../../../sections/@dashboard/general/menu/Tickets";
import TicketsWin from "../../../sections/@dashboard/general/menu/TicketsWin";
import LotGagnants from "../../../sections/@dashboard/general/menu/LotGagnants";
import Tirages from "../../../sections/@dashboard/general/menu/Tirages";
import Repports from "../../../sections/@dashboard/general/menu/Repports";

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: "100%",
    height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    backgroundColor: "#021a4f",
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenSidebar: PropTypes.func,
  verticalLayout: PropTypes.bool,
};

export default function Header() {
  const [openTickets, setOpenTickets] = useState(false);

  const handleOpenTickets = () => setOpenTickets(true);
  const handleCloseTickets = () => setOpenTickets(false);
  const [openTicketsWin, setOpenTicketsWin] = useState(false);

  const handleOpenTicketsWin = () => setOpenTicketsWin(true);
  const handleCloseTicketsWin = () => setOpenTicketsWin(false);

  const [openLotGagnants, setOpenLotGagnants] = useState(false);

  const handleOpenLotGagnants = () => setOpenLotGagnants(true);
  const handleCloseLotGagnants = () => setOpenLotGagnants(false);

  const [openTirages, setOpenTirages] = useState(false);

  const handleOpenTirages = () => setOpenTirages(true);
  const handleCloseTirages = () => setOpenTirages(false);

  const [openRepports, setOpenRepports] = useState(false);

  const handleOpenRepports = () => setOpenRepports(true);
  const handleCloseRepports = () => setOpenRepports(false);
  return (
    <RootStyle>
      <Toolbar
        sx={{
          minHeight: "100% !important",
        }}
      >
        <Stack direction="row" spacing={1.5}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3f51b5",
              color: "#ffffff",
              textTransform: "uppercase",
            }}
            onClick={handleOpenTickets}
          >
            Rechercher
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#e91e63",
              color: "#ffffff",
              textTransform: "uppercase",
            }}
            onClick={handleOpenTickets}
          >
            Fiches
          </Button>
          <Tickets open={openTickets} handleClose={handleCloseTickets} />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#673ab7",
              color: "#ffffff",
              textTransform: "uppercase",
            }}
            onClick={handleOpenTicketsWin}
          >
            F. Gagnant
          </Button>
          <TicketsWin
            open={openTicketsWin}
            handleClose={handleCloseTicketsWin}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#ff5722",
              color: "#ffffff",
              textTransform: "uppercase",
            }}
            onClick={handleOpenLotGagnants}
          >
            Lot Gagnant
          </Button>
          <LotGagnants
            open={openLotGagnants}
            handleClose={handleCloseLotGagnants}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#009688",
              color: "#ffffff",
              textTransform: "uppercase",
            }}
            onClick={handleOpenRepports}
          >
            Rapport
          </Button>
          <Repports open={openRepports} handleClose={handleCloseRepports} />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#cddc39",
              color: "#ffffff",
              textTransform: "uppercase",
            }}
            onClick={handleOpenTirages}
          >
            T. Disponible
          </Button>
          <Tirages open={openTirages} handleClose={handleCloseTirages} />
        </Stack>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <DateTimeDisplay
            style={{ color: "white", textTransform: "uppercase" }}
          />

          <TutoPopover />
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
