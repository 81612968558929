import React, { useState, useContext, createContext, useReducer } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Button,
  CircularProgress,
  Box,
  FormControlLabel,
  FormGroup
} from '@mui/material';

// ----------------------------------------------------------------------
const initialState = {
  dialogVisible: false,
  selectedValues: [],
  dialogConfig: {},
  resolve: null,
};

const handlers = {
  OPEN_DIALOG: (state, action) => {
    const { config, resolve } = action.payload;
    return {
      ...state,
      dialogConfig: config,
      dialogVisible: true,
      resolve,
    };
  },
  CLOSE_DIALOG: (state) => ({
    ...state,
    dialogVisible: false,
  }),
  SET_SELECTED_VALUES: (state, action) => ({
    ...state,
    selectedValues: action.payload,
  }),
  RESET_SELECTED_VALUES: (state) => ({
    ...state,
    selectedValues: [],
  }),
  SUBMIT_VALUE: (state) => {
    state.resolve && state.resolve(state.selectedValues);
    return {
      ...state,
      dialogVisible: false,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AskValueContext = createContext({
  ...initialState,
  openDialog: () => {},
});

function AskValueProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);

  const openDialog = (config) => {
    return new Promise((resolve) => {
      dispatch({ type: "RESET_SELECTED_VALUES" }); // Réinitialiser les valeurs
      dispatch({
        type: "OPEN_DIALOG",
        payload: {
          config,
          resolve,
        },
      });
    });
  };

  const closeDialog = () => {
    dispatch({ type: "CLOSE_DIALOG" });
  };

  const setSelectedValues = (value) => {
    dispatch({
      type: "SET_SELECTED_VALUES",
      payload: value,
    });
  };

  
  const options = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
    { value: 3, label: "Option 3" },
  ];


  const handleCheckboxChange = (option) => {
    const newSelectedValues = state.selectedValues.includes(option)
      ? state.selectedValues.filter((item) => item !== option)
      : [...state.selectedValues, option];
    setSelectedValues(newSelectedValues);
  };

  const submitValue = async () => {
    try {
      setLoading(true);
      dispatch({ type: "SUBMIT_VALUE" });
    } catch (error) {
      console.error("Erreur lors de la soumission :", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AskValueContext.Provider value={{ ...state, openDialog }}>
      {children}
      <Dialog open={state.dialogVisible} onClose={closeDialog}>
        <DialogTitle>{state.dialogConfig?.title || "Sélectionnez une ou plusieurs options :"}</DialogTitle>
        <DialogContent>
          <FormGroup>
            {options?.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={state.selectedValues.includes(option.value)}
                    onChange={() => handleCheckboxChange(option.value)}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <>
              <Button onClick={closeDialog} color="primary">
                Annuler
              </Button>
              <Button onClick={submitValue} color="primary">
                Valider
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </AskValueContext.Provider>
  );
}

const useAskValue = () => {
  const context = useContext(AskValueContext);
  if (!context) {
    throw new Error("useAskValue must be used within AskValueProvider");
  }
  return context;
};

export { AskValueProvider, useAskValue };