/* eslint-disable */
// ----------------------------------------------------------------------

export default function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const DEVICE_ID_KEY = "SGL-device-id";

const generateNumericId = (length) => {
  let result = "";
  const characters = "0123456789";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}; path=/`;
};

/**
 * Récupère la valeur d'un cookie par son nom
 */
const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }
  return null;
};


export const getDeviceId = () => {
  let deviceId = getCookie(DEVICE_ID_KEY);

  if (!deviceId) {
    // Si aucun cookie n'existe, générer un nouvel ID
    deviceId = generateNumericId(16);

    // Enregistrer l'ID dans un cookie (durée : 365 jours)
    setCookie(DEVICE_ID_KEY, deviceId, 365);
  }

  return deviceId;
};


export const generateUniqueId = (bets) => {
  return bets?.length + 1 + Date.now();
};